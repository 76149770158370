import Header from '../../components/portfolio/Header/Header'

export default function Experience() {
  return (
    <div>
      <Header />
      <h1>This is an Experience Page</h1>
    </div>
  )
}
